<template>
  <div class="step">
    <span class="font-14 color-fff">新密码</span>
    <div class="inline password">
      <input v-model="password" class="font-16" :type="passwordShow ? 'number' : 'password'" placeholder="6-16位密码，区分大小写" />
      <span class="show" :class="passwordShow ? 'pShow' : 'pHide'" @click="passwordShow = !passwordShow" />
      <div class="tips flex-center" v-show="tips">
        <span class="caution color-e6081f font-14">{{error}}</span>
      </div>
    </div>
    <button type="button" class="step_nextbtn color-fff font-14" @click="nextStep">下一步</button>
  </div>
</template>

<script>
import { loginApi } from '@/utils/config'

let params = {
  phone: '',
  code: '',
  pwd: ''
}

export default {
  name: 'Step2',
  data () {
    return {
      password: '',
      error: '',
      passwordShow: false,
      tips: false
    }
  },
  created () {
    if (this.$route.params.phoneNumber && this.$route.params.authCode) {
      params.phone = this.$route.params.phoneNumber
      params.code = this.$route.params.authCode
    }
  },
  methods: {
    nextStep () {
      if (this.password.length < 6 || this.password.length > 16) {
        this.tips = true
        this.error = '请输入6-16位密码'
        return
      }
      params.pwd = this.password
      let url = loginApi + 'admin/user/setPwd'
      this.$https.post(url, params).then(({code, msg}) => {
        if (code !== 200) {
          this.tips = true
          this.error = msg
          setTimeout(() => {
            this.$router.back(-1)
          }, 1000)
          return
        }
        this.$router.push({
          name: 'step3',
          params: {
            step: 3
          }
        })
      })
    }
  }
}
</script>

<style lang="less" scoped>
button {
  border: 0;
  padding: 0;
  height: 40px;
  outline: none;
  border-radius: 4px;
  background-image: linear-gradient(270deg, #01D18A 0%, #2483FF 100%);
  cursor: pointer;
}
input {
  height: 40px;
  margin-left: 12px;
  padding-left: 12px;
  background: #FFF;
  border-radius: 4px;
  border: 1px solid #D8D8D8;
  outline: none;
  box-sizing: border-box;
}
.step{
  position: relative;
  height: 310px;
  padding-top: 80px;
  text-align: center;
  box-sizing: border-box;
  .password {
    position: relative;
    width: 368px;
    margin-left: 12px;
    input {
      width: 100%;
      margin: 0;
      padding-right: 40px;
      box-sizing: border-box;
    }
    .show {
      position: absolute;
      top: 50%;
      right: 12px;
      width: 16px;
      height: 16px;
      background-position: center center;
      background-repeat: no-repeat;
      background-size: contain;
      transform: translateY(-50%);
      cursor: pointer;
    }
    .pHide {
      background-image: url('/assets/close_eye.png');
    }
    .pShow {
      background-image: url('/assets/eye.png');
    }
    .tips {
      position: absolute;
      bottom: -37px;
      left: 0;
      .caution {
        position: relative;
        padding-left: 23px;
        &::before {
          position: absolute;
          content: '';
          width: 16px;
          height: 16px;
          left: 0px;
          top: 50%;
          background-position: center center;
          background-repeat: no-repeat;
          background-size: contain;
          transform: translateY(-50%);
          background-image: url('/assets/caution.png');
        }
      }
    }
  }
  .step_nextbtn {
    position: absolute;
    left: 50%;
    transform: translateX(-50%);
    bottom: 73px;
    width: 85px;
  }
  .inline {
    display: inline-block;
  }
}
</style>
